import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import TableHeader from "../../../../../components/TableHeader";
import PickingItem from "./PickingItem";

const TableHeadCounter = styled.div`
  min-width: 30.4rem;
  padding-left: 2.4rem;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
`;

const PickingTable = ({ picking, session }) => {
  return (
    <>
      <TableHeader>
        <div className="column">
          <p className="title is-5">
            <FormattedMessage
              id="app.pages.application.material.table.item"
              defaultMessage="Item"
            />
          </p>
        </div>
        <TableHeadCounter className="column is-narrow has-right-text">
          <p className="title is-5">
            <FormattedMessage
              id="app.pages.application.supply.picking_item.producible"
              defaultMessage="Producible units"
            />
          </p>
        </TableHeadCounter>
        <TableHeadCounter className="column is-narrow has-right-text">
          <p className="title is-5">
            <FormattedMessage
              id="app.pages.application.supply.picking_item.remaining"
              defaultMessage="Remaining units"
            />
          </p>
        </TableHeadCounter>
      </TableHeader>
      <div className="columns has-text-centered">
        <div className="column is-fullwidth">
          {picking?.map((pickingItem) => (
            <PickingItem
              key={pickingItem._id}
              picking={pickingItem}
              alertThreshold={session.alertThreshold}
              produced={session.produced}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PickingTable;
