import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const StyledWrapper = styled.div`
  position: relative;

  &.disabled {
  }

  .image {
    margin-bottom: 0.8rem;
    width: 13rem !important;
    height: 13rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
  }
`;

function AddContainer({ onClick, type = "add" }) {
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <a
        onClick={onClick}
        data-cy="application-containers-container"
        data-cy-id="add-container"
      >
        <figure className="image">
          {type === "add" ? (
            <i
              className="icon-arrow-plus-small-icn"
              style={{ fontSize: "5.2rem" }}
            />
          ) : (
            <div
              style={{ fontSize: "7.2rem", transform: "translateY(-1.8rem)" }}
            >
              ...
            </div>
          )}
        </figure>
        <h4 className="title is-4 w500 has-text-centered">
          {type === "add" ? (
            <FormattedMessage
              id="app.shared.container_add"
              defaultMessage="Add container"
            />
          ) : (
            <FormattedMessage
              id="app.shared.container_all"
              defaultMessage="All containers"
            />
          )}
        </h4>
      </a>
    </StyledWrapper>
  );
}

export default AddContainer;
