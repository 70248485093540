import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Unit from "../../Unit";

const StyledTable = styled.table`
  thead {
    top: 0;
    z-index: 2;
    position: sticky;
    background: white;
  }
  th,
  td {
    line-height: 3rem;
    font-size: 2.2rem;
  }
`;

function PickingTable({ picking }) {
  return (
    <StyledTable className="table is-striped main">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="app.components.picking_list.th.code"
              defaultMessage="Code"
            />
          </th>
          <th>
            <FormattedMessage
              id="app.components.picking_list.th.name"
              defaultMessage="Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="app.components.picking_list.th.quantity"
              defaultMessage="Quantity"
            />
          </th>
          <th>
            <FormattedMessage
              id="app.components.picking_list.th.notes"
              defaultMessage="Notes"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {picking.map((item) => (
          <tr key={item._id}>
            <td>{item.item.code}</td>
            <td>{item.item.name}</td>
            <td>
              <Unit>
                {item.qty} {item.item.unit}
              </Unit>
            </td>
            <td className="subtitle is-6 fg-bluish-grey">{item.notes}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

PickingTable.propTypes = {
  picking: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      qty: PropTypes.number,
      item: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        unit: PropTypes.string,
      }),
    })
  ),
};
export default PickingTable;
