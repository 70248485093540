import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Button from "../../../../../components/Button";
import ContainerActions from "../../../../../components/ContainerActions";
import ListElement from "../../../../../components/ListElement";
import NumberFormatter from "../../../../../libs/format-number";
import themes from "../../../../../libs/themes";
import SerialnumbersBadge from "../../../../../components/SerialnumbersBadge";
import Unit from "../../../../../components/Unit";

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 1.2rem 0.4rem 0.9rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

const RemoveButton = styled(Button)`
  background-color: #d9e0ec;
  height: 6.8rem;
  font-size: 3.2rem;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

export default function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const serialnumbers = props.container.serialnumbers;

  var articleBadge =
    props.pickingItem._item &&
    props.container._item &&
    props.pickingItem._item == props.container._item ? (
      <Badge>
        <i className="icon-product" />
        {props.pickingItem.item.code}
      </Badge>
    ) : (
      <Badge className="error">
        <i className="icon-product" />
        <FormattedMessage
          id="app.pages.application.material.table.article.nan"
          defaultMessage="Articolo errato"
        />
      </Badge>
    );

  var batchBadge = props.container.batch ? (
    <Badge>
      <i className="icon-doc-text" />
      {props.container.batch.code}
    </Badge>
  ) : (
    <Badge className="error">
      <i className="icon-doc-text" />
      <FormattedMessage
        id="app.pages.application.material.table.batch.na"
        defaultMessage="Inserisci lotto"
      />
    </Badge>
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      onClick={() => {
        props.onClick();
      }}
      {...listeners}
    >
      <ListElement
        theme={
          !props.container.isVirtual
            ? themes.default.listElement
            : themes.neutralGrey.listElement
        }
        data-cy="application-supply-item"
        data-cy-id={props.container._id}
        key={props.container._id}
        style={{ position: "relative" }}
        selected={props.selected}
        hasTrailing={true}
        isContainer={!props.container.isVirtual}
      >
        <div className={"columns is-vcentered is-fullwidth is-marginless"}>
          <div className="column">
            <p className="subtitle is-6">
              <SerialnumbersBadge serialnumbers={serialnumbers} />
              {batchBadge}
              {articleBadge}
            </p>
            {
              <p className="title is-4">
                {!props.container.isVirtual
                  ? props.container.name
                  : props.container.batch?.code}
              </p>
            }
          </div>
          <div className="column is-3 is-paddingless-left">
            <p
              className="title is-3"
              style={{ transform: "translateX(-18px)" }}
            >
              <NumberFormatter
                value={props.container.current ?? props.pickingItem.remaining}
              />
              &nbsp;
              {props.pickingItem.item?.unit && (
                <Unit>
                  <span
                    className="unit subtitle is-5"
                    style={{ fontWeight: "400" }}
                  >
                    {props.pickingItem.item.unit}
                  </span>
                </Unit>
              )}
            </p>
          </div>

          <div className="column is-2 is-paddingless-left">
            <div className="columns">
              <div className="column">
                {
                  <ContainerActions
                    container={props.container}
                    isPopup={true}
                  />
                }
              </div>
              <div className="column">
                {
                  <RemoveButton
                    isFullWidth
                    theme={themes.welcome.buttons}
                    onClick={(e) => {
                      props.onDelete(props.container._id);
                      e.stopPropagation();
                    }}
                    data-cy="application-button-next"
                  >
                    <i className="icon-giphy-delete-white" />
                  </RemoveButton>
                }
              </div>
            </div>
          </div>
        </div>
      </ListElement>
    </div>
  );
}
