import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useIntl } from "react-intl";
import NumberFormatter from "../libs/format-number";

function Unit({
  unit,
  withDescription = false,
  children,
  className,
  ...props
}) {
  const intl = useIntl();

  const translateChildUnit = (child) => {
    if (
      typeof child === "string" &&
      intl.messages[`app.components.unit.${child}`]
    ) {
      return intl.formatMessage({
        id: `app.components.unit.${child}`,
        defaultMessage: child,
      });
    }
    return child;
  };

  return (
    <>
      {children && (
        <Choose>
          <When condition={!isNaN(children)}>
            <NumberFormatter value={children} />
          </When>
          <When condition={Array.isArray(children)}>
            {children.map((child, index) => (
              <React.Fragment key={index}>
                {translateChildUnit(child)}
              </React.Fragment>
            ))}
          </When>
          <Otherwise>{children}</Otherwise>
        </Choose>
      )}
      <If condition={withDescription}>
        {" "}
        <Choose>
          <When condition={!!intl.messages[`app.components.unit.${unit}`]}>
            {intl.formatMessage({ id: `app.components.unit.${unit}` })}
          </When>
          <Otherwise>{unit}</Otherwise>
        </Choose>
      </If>
      {unit && (
        <Choose>
          <When condition={["unit", "un"].includes(unit)}>
            <i
              className={classNames("icon-giphy-measurement-units", className)}
              {...props}
              title={unit}
            />
          </When>
          <When condition={["lt", "dl", "cl", "ml"].includes(unit)}>
            <i
              className={classNames("icon-giphy-measurement-liquid", className)}
              {...props}
              title={unit}
            />
          </When>
          <When condition={["ton", "kg", "qt", "dg", "hg", "g"].includes(unit)}>
            <i
              className={classNames("icon-giphy-measurement-weight", className)}
              {...props}
              title={unit}
            />
          </When>
          {/*TODO Change the icons for length unit and double length*/}
          {/*<When condition={["mt", "dm", "cm", "mm"].includes(unit)}>*/}
          {/*  <i*/}
          {/*    className={classNames("icon-giphy-measurement-length", className)}*/}
          {/*    {...props}*/}
          {/*    title={unit}*/}
          {/*  />*/}
          {/*</When>*/}
          {/*<When condition={unit === "sqm"}>*/}
          {/*  <i*/}
          {/*    className={classNames(*/}
          {/*      "icon-giphy-measurement-length-double",*/}
          {/*      className*/}
          {/*    )}*/}
          {/*    {...props}*/}
          {/*    title={unit}*/}
          {/*  />*/}
          {/*</When>*/}
          <When condition={unit === "pair"}>
            <i
              className={classNames("icon-giphy-measurement-pair", className)}
              {...props}
              title={unit}
            />
          </When>
          <When condition={unit === "kit"}>
            <i
              className={classNames("icon-giphy-measurement-kit", className)}
              {...props}
              title={unit}
            />
          </When>
          <When condition={unit === "na"}>
            <i className={classNames(className)} {...props} title={unit} />
          </When>
          <Otherwise>
            <i
              className={classNames("icon-giphy-measurement-units", className)}
              {...props}
              title={unit}
            />
          </Otherwise>
        </Choose>
      )}
    </>
  );
}

Unit.propTypes = {
  withDescription: PropTypes.bool,
  unit: PropTypes.string,
  className: PropTypes.string,
};
export default Unit;
